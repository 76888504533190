import React, {useEffect} from 'react'
// import Pricing22 from '../../../Photos/Frame 114.png';
import './Pricing2.css'

function Pricing2() { 
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
  <>
      {/* <img className ="ant"src ={Pricing22} /> */}
</>
  )
}
export default Pricing2;