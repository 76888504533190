
const jobDescriptions = [
  {
    id: 0,
    title: "Software Developer",
    Category:"IT",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
   responsibilities: ["Develop and maintain high-quality software applications, Collaborate with cross-functional teams to define, design, and ship new features, Write clean, maintainable code that is scalable and efficient"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in one or more programming languages (e.g., JavaScript, Python, Java)", "Experience with web development frameworks (e.g., React, Angular, Vue)"],
    location: "San Francisco, CA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 1,
    title: "Software Engineer",
    Category:"IT",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Develop and maintain high-quality software applications", "Collaborate with cross-functional teams to define, design, and ship new features", "Write clean, maintainable code that is scalable and efficient"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in one or more programming languages (e.g., JavaScript, Python, Java)", "Experience with web development frameworks (e.g., React, Angular, Vue)"],
    location: "San Francisco, CA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 2,
    title: "Data Scientist",
    Category:"IT",
    type: "Part Time",
    jobloc:"Remote",
     date:"2024-03-12",
    responsibilities: ["Analyze complex datasets to derive insights and make data-driven decisions", "Develop predictive models and algorithms to solve business problems", "Communicate findings to stakeholders through reports and presentations"],
    qualifications: ["Master's or PhD in a quantitative field (e.g., Statistics, Mathematics, Computer Science)", "Proficiency in statistical analysis tools (e.g., R, Python)", "Experience with machine learning techniques and frameworks (e.g., TensorFlow, scikit-learn)"],
    location: "New York, NY",
    salary: "$90,000 - $140,000 per year"
  },
  {
    id: 3,
    title: "Marketing Manager",
    Category:"Marketing",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Develop and implement strategic marketing plans to drive brand awareness and customer acquisition", "Manage digital marketing campaigns across various channels (e.g., social media, email, PPC)", "Analyze campaign performance and optimize for ROI"],
    qualifications: ["Bachelor's degree in Marketing, Business, or related field", "Proven experience in marketing roles, with a focus on digital marketing", "Strong analytical skills and proficiency in marketing analytics tools (e.g., Google Analytics)"],
    location: "Los Angeles, CA",
    salary: "$70,000 - $110,000 per year"
  },
  {
    id: 4,
    title: "UX/UI Designer",
    Category:"Designing",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Create user-centered designs for web and mobile applications", "Collaborate with product managers and developers to translate requirements into intuitive and engaging user interfaces", "Conduct user research and usability testing to gather feedback and iterate designs"],
    qualifications: ["Bachelor's or Master's degree in Design, Human-Computer Interaction, or related field", "Proficiency in design tools (e.g., Adobe XD, Sketch, Figma)", "Strong portfolio showcasing UX/UI design work and problem-solving skills"],
    location: "Seattle, WA",
    salary: "$75,000 - $120,000 per year"
  },
  {
    id: 5,
    title: "Accountant",
    Category:"CA",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Manage financial records and transactions, including accounts payable/receivable and payroll", "Prepare financial statements and reports for internal and external stakeholders", "Ensure compliance with accounting standards and regulations"],
    qualifications: ["Bachelor's degree in Accounting, Finance, or related field", "CPA certification is preferred", "Experience with accounting software (e.g., QuickBooks, SAP)"],
    location: "Chicago, IL",
    salary: "$60,000 - $90,000 per year"
  },
  {
    id: 6,
    title: "Product Manager",
    Category:"HR",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Define product vision, strategy, and roadmap based on market and customer needs", "Work closely with cross-functional teams to prioritize features and deliver product releases on time", "Analyze user feedback and data to iterate and improve product offerings"],
    qualifications: ["Bachelor's or Master's degree in Business, Engineering, or related field", "Proven experience in product management roles, preferably in the tech industry", "Strong analytical and problem-solving skills"],
    location: "Austin, TX",
    salary: "$100,000 - $150,000 per year"
  },
  {
    id: 7,
    title: "Graphic Designer",
    Category:"IT",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Create visually appealing designs for print and digital media", "Collaborate with clients and stakeholders to understand design requirements and deliver solutions that meet their needs", "Stay updated on design trends and best practices to ensure high-quality output"],
    qualifications: ["Bachelor's degree in Graphic Design or related field", "Proficiency in design software (e.g., Adobe Photoshop, Illustrator, InDesign)", "Strong portfolio showcasing design skills and creative projects"],
    location: "Miami, FL",
    salary: "$50,000 - $80,000 per year"
  },
  {
    id: 8,
    title: "Frontend Developer",
    Category:"IT",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Develop user-facing features for web applications using HTML, CSS, and JavaScript", "Collaborate with designers and backend developers to implement responsive and visually appealing interfaces", "Optimize web applications for maximum speed and scalability"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in frontend technologies and frameworks (e.g., React, Angular, Vue)", "Experience with version control systems (e.g., Git)"],
    location: "Denver, CO",
    salary: "$70,000 - $110,000 per year"
  },
  {
    id: 9,
    title: "Human Resources Manager",
    Category:"HR",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Develop and implement HR strategies and initiatives aligned with the overall business objectives", "Oversee recruitment and hiring processes, including sourcing candidates, conducting interviews, and onboarding new hires", "Manage employee relations, performance management, and compliance with labor laws and regulations"],
    qualifications: ["Bachelor's or Master's degree in Human Resources, Business Administration, or related field", "Proven experience in HR management roles, with a focus on talent acquisition and employee relations", "Strong communication, interpersonal, and leadership skills"],
    location: "Atlanta, GA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 10,
    title: "Financial Analyst",
    Category:"CA",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Conduct financial analysis and modeling to support strategic decision-making and business planning", "Prepare financial reports, forecasts, and budgeting analyses for management and stakeholders", "Monitor key financial metrics and performance indicators to identify trends and areas for improvement"],
    qualifications: ["Bachelor's degree in Finance, Accounting, Economics, or related field", "Strong quantitative and analytical skills, with proficiency in financial modeling and Excel", "Experience with financial analysis software (e.g., Bloomberg, FactSet)"],
    location: "Houston, TX",
    salary: "$70,000 - $100,000 per year"
  },
  {
    id: 11,
    title: "Content Writer",
    Category:"Marketing",
    type: "Part Time",
    jobloc:"Remote",
    date:"2024-03-12",
    responsibilities: ["Create engaging and informative content for websites, blogs, social media, and other digital platforms", "Research topics and industry trends to generate ideas for new content", "Optimize content for SEO and audience engagement, ensuring it aligns with brand voice and messaging"],
    qualifications: ["Bachelor's degree in English, Journalism, Communications, or related field", "Excellent writing, editing, and proofreading skills", "Experience with content management systems (e.g., WordPress) and SEO best practices"],
    location: "Portland, OR",
    salary: "$45,000 - $70,000 per year"
  },
  {
    id: 12,
    title: "Product Manager",
    Category:"HR",
    type: "Full Time",
    jobloc:"Remote",
    date:"2023-12-12",
    responsibilities: ["Define product vision, strategy, and roadmap based on market and customer needs", "Work closely with cross-functional teams to prioritize features and deliver product releases on time", "Analyze user feedback and data to iterate and improve product offerings"],
    qualifications: ["Bachelor's or Master's degree in Business, Engineering, or related field", "Proven experience in product management roles, preferably in the tech industry", "Strong analytical and problem-solving skills"],
    location: "Austin, TX",
    salary: "$100,000 - $150,000 per year"
  },
  {
    id: 13,
    title: "Graphic Designer",
    Category:"IT",
    type: "Full Time",
    jobloc:"Remote",
    date:"2023-12-12",
    responsibilities: ["Create visually appealing designs for print and digital media", "Collaborate with clients and stakeholders to understand design requirements and deliver solutions that meet their needs", "Stay updated on design trends and best practices to ensure high-quality output"],
    qualifications: ["Bachelor's degree in Graphic Design or related field", "Proficiency in design software (e.g., Adobe Photoshop, Illustrator, InDesign)", "Strong portfolio showcasing design skills and creative projects"],
    location: "Miami, FL",
    salary: "$50,000 - $80,000 per year"
  },
  {
    id: 14,
    title: "Frontend Developer",
    Category:"IT",
    type: "Full Time",
    jobloc:"Remote",
    date:"2023-12-12",
    responsibilities: ["Develop user-facing features for web applications using HTML, CSS, and JavaScript", "Collaborate with designers and backend developers to implement responsive and visually appealing interfaces", "Optimize web applications for maximum speed and scalability"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in frontend technologies and frameworks (e.g., React, Angular, Vue)", "Experience with version control systems (e.g., Git)"],
    location: "Denver, CO",
    salary: "$70,000 - $110,000 per year"
  },
  {
    id: 15,
    title: "Human Resources Manager",
    Category:"HR",
    type: "Full Time",
    jobloc:"Remote",
    date:"2023-12-12",
    responsibilities: ["Develop and implement HR strategies and initiatives aligned with the overall business objectives", "Oversee recruitment and hiring processes, including sourcing candidates, conducting interviews, and onboarding new hires", "Manage employee relations, performance management, and compliance with labor laws and regulations"],
    qualifications: ["Bachelor's or Master's degree in Human Resources, Business Administration, or related field", "Proven experience in HR management roles, with a focus on talent acquisition and employee relations", "Strong communication, interpersonal, and leadership skills"],
    location: "Atlanta, GA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 16,
    title: "Financial Analyst",
    Category:"CA",
    type: "Full Time",
    jobloc:"Remote",
    date:"2023-12-12",
    responsibilities: ["Conduct financial analysis and modeling to support strategic decision-making and business planning", "Prepare financial reports, forecasts, and budgeting analyses for management and stakeholders", "Monitor key financial metrics and performance indicators to identify trends and areas for improvement"],
    qualifications: ["Bachelor's degree in Finance, Accounting, Economics, or related field", "Strong quantitative and analytical skills, with proficiency in financial modeling and Excel", "Experience with financial analysis software (e.g., Bloomberg, FactSet)"],
    location: "Houston, TX",
    salary: "$70,000 - $100,000 per year"
  },
  {
    id: 17,
    title: "Content Writer",
    Category:"Marketing",
    type: "Full Time",
    jobloc:"Remote",
    date:"2023-12-12",
    responsibilities: ["Create engaging and informative content for websites, blogs, social media, and other digital platforms", "Research topics and industry trends to generate ideas for new content", "Optimize content for SEO and audience engagement, ensuring it aligns with brand voice and messaging"],
    qualifications: ["Bachelor's degree in English, Journalism, Communications, or related field", "Excellent writing, editing, and proofreading skills", "Experience with content management systems (e.g., WordPress) and SEO best practices"],
    location: "Portland, OR",
    salary: "$45,000 - $70,000 per year"
  },
  {
    id: 18,
    title: "Software Developer",
    Category:"IT",
    type: "Full Time",
    jobloc:"Office",
    date:"2023-12-12",
    responsibilities: ["Develop and maintain high-quality software applications", "Collaborate with cross-functional teams to define, design, and ship new features", "Write clean, maintainable code that is scalable and efficient"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in one or more programming languages (e.g., JavaScript, Python, Java)", "Experience with web development frameworks (e.g., React, Angular, Vue)"],
    location: "San Francisco, CA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 19,
    title: "Software Engineer",
    Category:"IT",
    type: "Full Time",
    jobloc:"Office",
    date:"2023-12-12",
    responsibilities: ["Develop and maintain high-quality software applications", "Collaborate with cross-functional teams to define, design, and ship new features", "Write clean, maintainable code that is scalable and efficient"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in one or more programming languages (e.g., JavaScript, Python, Java)", "Experience with web development frameworks (e.g., React, Angular, Vue)"],
    location: "San Francisco, CA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 20,
    title: "Data Scientist",
    Category:"IT",
    type: "Full Time",
    jobloc:"Office",
    date:"2023-12-12",
    responsibilities: ["Analyze complex datasets to derive insights and make data-driven decisions", "Develop predictive models and algorithms to solve business problems", "Communicate findings to stakeholders through reports and presentations"],
    qualifications: ["Master's or PhD in a quantitative field (e.g., Statistics, Mathematics, Computer Science)", "Proficiency in statistical analysis tools (e.g., R, Python)", "Experience with machine learning techniques and frameworks (e.g., TensorFlow, scikit-learn)"],
    location: "New York, NY",
    salary: "$90,000 - $140,000 per year"
  },
  {
    id: 21,
    title: "Marketing Manager",
    Category:"Marketing",
    type: "Full Time",
    jobloc:"Office",
    date:"2023-12-12",
    responsibilities: ["Develop and implement strategic marketing plans to drive brand awareness and customer acquisition", "Manage digital marketing campaigns across various channels (e.g., social media, email, PPC)", "Analyze campaign performance and optimize for ROI"],
    qualifications: ["Bachelor's degree in Marketing, Business, or related field", "Proven experience in marketing roles, with a focus on digital marketing", "Strong analytical skills and proficiency in marketing analytics tools (e.g., Google Analytics)"],
    location: "Los Angeles, CA",
    salary: "$70,000 - $110,000 per year"
  },
  {
    id: 22,
    title: "UX/UI Designer",
    Category:"Designing",
    type: "Internship",
    jobloc:"Office",
    date:"2024-1-12",
    responsibilities: ["Create user-centered designs for web and mobile applications", "Collaborate with product managers and developers to translate requirements into intuitive and engaging user interfaces", "Conduct user research and usability testing to gather feedback and iterate designs"],
    qualifications: ["Bachelor's or Master's degree in Design, Human-Computer Interaction, or related field", "Proficiency in design tools (e.g., Adobe XD, Sketch, Figma)", "Strong portfolio showcasing UX/UI design work and problem-solving skills"],
    location: "Seattle, WA",
    salary: "$75,000 - $120,000 per year"
  },
  {
    id: 23,
    title: "Accountant",
    Category:"CA",
    type: "Internship",
    jobloc:"Office",
    date:"2024-1-12",
    responsibilities: ["Manage financial records and transactions, including accounts payable/receivable and payroll", "Prepare financial statements and reports for internal and external stakeholders", "Ensure compliance with accounting standards and regulations"],
    qualifications: ["Bachelor's degree in Accounting, Finance, or related field", "CPA certification is preferred", "Experience with accounting software (e.g., QuickBooks, SAP)"],
    location: "Chicago, IL",
    salary: "$60,000 - $90,000 per year"
  },
  {
    id: 24,
    title: "Product Manager",
    Category:"Marketing",
     type: "Internship",
     jobloc:"Office",
     date:"2024-1-12",
    responsibilities: ["Define product vision, strategy, and roadmap based on market and customer needs", "Work closely with cross-functional teams to prioritize features and deliver product releases on time", "Analyze user feedback and data to iterate and improve product offerings"],
    qualifications: ["Bachelor's or Master's degree in Business, Engineering, or related field", "Proven experience in product management roles, preferably in the tech industry", "Strong analytical and problem-solving skills"],
    location: "Austin, TX",
    salary: "$100,000 - $150,000 per year"
  },
  {
    id: 25,
    title: "Graphic Designer",
    Category:"Designing",
     type: "Internship",
     jobloc:"Office",
     date:"2024-1-12",
    responsibilities: ["Create visually appealing designs for print and digital media", "Collaborate with clients and stakeholders to understand design requirements and deliver solutions that meet their needs", "Stay updated on design trends and best practices to ensure high-quality output"],
    qualifications: ["Bachelor's degree in Graphic Design or related field", "Proficiency in design software (e.g., Adobe Photoshop, Illustrator, InDesign)", "Strong portfolio showcasing design skills and creative projects"],
    location: "Miami, FL",
    salary: "$50,000 - $80,000 per year"
  },
  {
    id: 26,
    title: "Frontend Developer",
    Category:"IT",
     type: "Internship",
     jobloc:"Office",
     date:"2024-1-12",
    responsibilities: ["Develop user-facing features for web applications using HTML, CSS, and JavaScript", "Collaborate with designers and backend developers to implement responsive and visually appealing interfaces", "Optimize web applications for maximum speed and scalability"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in frontend technologies and frameworks (e.g., React, Angular, Vue)", "Experience with version control systems (e.g., Git)"],
    location: "Denver, CO",
    salary: "$70,000 - $110,000 per year"
  },
  {
    id: 27,
    title: "Human Resources Manager",
    Category:"HR",
    type: "Internship",
    jobloc:"Office",
    date:"2024-1-12",
    responsibilities: ["Develop and implement HR strategies and initiatives aligned with the overall business objectives", "Oversee recruitment and hiring processes, including sourcing candidates, conducting interviews, and onboarding new hires", "Manage employee relations, performance management, and compliance with labor laws and regulations"],
    qualifications: ["Bachelor's or Master's degree in Human Resources, Business Administration, or related field", "Proven experience in HR management roles, with a focus on talent acquisition and employee relations", "Strong communication, interpersonal, and leadership skills"],
    location: "Atlanta, GA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 28,
    title: "Frontend Developer",
    Category:"IT",
     type: "Internship",
     jobloc:"Office",
     date:"2024-1-12",
    responsibilities: ["Develop user-facing features for web applications using HTML, CSS, and JavaScript", "Collaborate with designers and backend developers to implement responsive and visually appealing interfaces", "Optimize web applications for maximum speed and scalability"],
    qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in frontend technologies and frameworks (e.g., React, Angular, Vue)", "Experience with version control systems (e.g., Git)"],
    location: "Denver, CO",
    salary: "$70,000 - $110,000 per year"
  },
  {
    id: 29,
    title: "Human Resources Manager",
    Category:"IT",
     type: "Internship",
     jobloc:"Office",
     date:"2024-1-12",
    responsibilities: ["Develop and implement HR strategies and initiatives aligned with the overall business objectives", "Oversee recruitment and hiring processes, including sourcing candidates, conducting interviews, and onboarding new hires", "Manage employee relations, performance management, and compliance with labor laws and regulations"],
    qualifications: ["Bachelor's or Master's degree in Human Resources, Business Administration, or related field", "Proven experience in HR management roles, with a focus on talent acquisition and employee relations", "Strong communication, interpersonal, and leadership skills"],
    location: "Atlanta, GA",
    salary: "$80,000 - $120,000 per year"
  },
  {
    id: 30,
    title: "Financial Analyst",
    Category:"IT",
     type: "Internship",
     jobloc:"Office",
     date:"2024-1-12",
    responsibilities: ["Conduct financial analysis and modeling to support strategic decision-making and business planning", "Prepare financial reports, forecasts, and budgeting analyses for management and stakeholders", "Monitor key financial metrics and performance indicators to identify trends and areas for improvement"],
    qualifications: ["Bachelor's degree in Finance, Accounting, Economics, or related field", "Strong quantitative and analytical skills, with proficiency in financial modeling and Excel", "Experience with financial analysis software (e.g., Bloomberg, FactSet)"],
    location: "Houston, TX",
    salary: "$70,000 - $100,000 per year"
  },
  {
    id: 31,
    title: "Content Writer",
    Category:"Marketing",
     type: "Internship",
     jobloc:"Office",
     date:"2024-1-12",
    responsibilities: ["Create engaging and informative content for websites, blogs, social media, and other digital platforms", "Research topics and industry trends to generate ideas for new content", "Optimize content for SEO and audience engagement, ensuring it aligns with brand voice and messaging"],
    qualifications: ["Bachelor's degree in English, Journalism, Communications, or related field", "Excellent writing, editing, and proofreading skills", "Experience with content management systems (e.g., WordPress) and SEO best practices"],
    location: "Portland, OR",
    salary: "$45,000 - $70,000 per year"
  },
]
  // console.log(jobDescriptions);
  export default jobDescriptions;
  
// import React from 'react'
// import Career from './content/career/career';
// export const  jobDetails = React.createContext(); 

// function data() {  
  
// const jobDescriptions = [
//   {
//     id: 0,
//     title: "Software Developer",
//     responsibilities: ["Develop and maintain high-quality software applications", "Collaborate with cross-functional teams to define, design, and ship new features", "Write clean, maintainable code that is scalable and efficient"],
//     qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in one or more programming languages (e.g., JavaScript, Python, Java)", "Experience with web development frameworks (e.g., React, Angular, Vue)"],
//     location: "San Francisco, CA",
//     salary: "$80,000 - $120,000 per year"
//   },
//   {
//     id: 1,
//     title: "Software Engineer",
//     responsibilities: ["Develop and maintain high-quality software applications", "Collaborate with cross-functional teams to define, design, and ship new features", "Write clean, maintainable code that is scalable and efficient"],
//     qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in one or more programming languages (e.g., JavaScript, Python, Java)", "Experience with web development frameworks (e.g., React, Angular, Vue)"],
//     location: "San Francisco, CA",
//     salary: "$80,000 - $120,000 per year"
//   },
//   {
//     id: 2,
//     title: "Data Scientist",
//     responsibilities: ["Analyze complex datasets to derive insights and make data-driven decisions", "Develop predictive models and algorithms to solve business problems", "Communicate findings to stakeholders through reports and presentations"],
//     qualifications: ["Master's or PhD in a quantitative field (e.g., Statistics, Mathematics, Computer Science)", "Proficiency in statistical analysis tools (e.g., R, Python)", "Experience with machine learning techniques and frameworks (e.g., TensorFlow, scikit-learn)"],
//     location: "New York, NY",
//     salary: "$90,000 - $140,000 per year"
//   },
//   {
//     id: 3,
//     title: "Marketing Manager",
//     responsibilities: ["Develop and implement strategic marketing plans to drive brand awareness and customer acquisition", "Manage digital marketing campaigns across various channels (e.g., social media, email, PPC)", "Analyze campaign performance and optimize for ROI"],
//     qualifications: ["Bachelor's degree in Marketing, Business, or related field", "Proven experience in marketing roles, with a focus on digital marketing", "Strong analytical skills and proficiency in marketing analytics tools (e.g., Google Analytics)"],
//     location: "Los Angeles, CA",
//     salary: "$70,000 - $110,000 per year"
//   },
//   {
//     id: 4,
//     title: "UX/UI Designer",
//     responsibilities: ["Create user-centered designs for web and mobile applications", "Collaborate with product managers and developers to translate requirements into intuitive and engaging user interfaces", "Conduct user research and usability testing to gather feedback and iterate designs"],
//     qualifications: ["Bachelor's or Master's degree in Design, Human-Computer Interaction, or related field", "Proficiency in design tools (e.g., Adobe XD, Sketch, Figma)", "Strong portfolio showcasing UX/UI design work and problem-solving skills"],
//     location: "Seattle, WA",
//     salary: "$75,000 - $120,000 per year"
//   },
//   {
//     id: 5,
//     title: "Accountant",
//     responsibilities: ["Manage financial records and transactions, including accounts payable/receivable and payroll", "Prepare financial statements and reports for internal and external stakeholders", "Ensure compliance with accounting standards and regulations"],
//     qualifications: ["Bachelor's degree in Accounting, Finance, or related field", "CPA certification is preferred", "Experience with accounting software (e.g., QuickBooks, SAP)"],
//     location: "Chicago, IL",
//     salary: "$60,000 - $90,000 per year"
//   },
//   {
//     id: 6,
//     title: "Product Manager",
//     responsibilities: ["Define product vision, strategy, and roadmap based on market and customer needs", "Work closely with cross-functional teams to prioritize features and deliver product releases on time", "Analyze user feedback and data to iterate and improve product offerings"],
//     qualifications: ["Bachelor's or Master's degree in Business, Engineering, or related field", "Proven experience in product management roles, preferably in the tech industry", "Strong analytical and problem-solving skills"],
//     location: "Austin, TX",
//     salary: "$100,000 - $150,000 per year"
//   },
//   {
//     id: 7,
//     title: "Graphic Designer",
//     responsibilities: ["Create visually appealing designs for print and digital media", "Collaborate with clients and stakeholders to understand design requirements and deliver solutions that meet their needs", "Stay updated on design trends and best practices to ensure high-quality output"],
//     qualifications: ["Bachelor's degree in Graphic Design or related field", "Proficiency in design software (e.g., Adobe Photoshop, Illustrator, InDesign)", "Strong portfolio showcasing design skills and creative projects"],
//     location: "Miami, FL",
//     salary: "$50,000 - $80,000 per year"
//   },
//   {
//     id: 8,
//     title: "Frontend Developer",
//     responsibilities: ["Develop user-facing features for web applications using HTML, CSS, and JavaScript", "Collaborate with designers and backend developers to implement responsive and visually appealing interfaces", "Optimize web applications for maximum speed and scalability"],
//     qualifications: ["Bachelor's degree in Computer Science or related field", "Proficiency in frontend technologies and frameworks (e.g., React, Angular, Vue)", "Experience with version control systems (e.g., Git)"],
//     location: "Denver, CO",
//     salary: "$70,000 - $110,000 per year"
//   },
//   {
//     id: 9,
//     title: "Human Resources Manager",
//     responsibilities: ["Develop and implement HR strategies and initiatives aligned with the overall business objectives", "Oversee recruitment and hiring processes, including sourcing candidates, conducting interviews, and onboarding new hires", "Manage employee relations, performance management, and compliance with labor laws and regulations"],
//     qualifications: ["Bachelor's or Master's degree in Human Resources, Business Administration, or related field", "Proven experience in HR management roles, with a focus on talent acquisition and employee relations", "Strong communication, interpersonal, and leadership skills"],
//     location: "Atlanta, GA",
//     salary: "$80,000 - $120,000 per year"
//   },
//   {
//     id: 10,
//     title: "Financial Analyst",
//     responsibilities: ["Conduct financial analysis and modeling to support strategic decision-making and business planning", "Prepare financial reports, forecasts, and budgeting analyses for management and stakeholders", "Monitor key financial metrics and performance indicators to identify trends and areas for improvement"],
//     qualifications: ["Bachelor's degree in Finance, Accounting, Economics, or related field", "Strong quantitative and analytical skills, with proficiency in financial modeling and Excel", "Experience with financial analysis software (e.g., Bloomberg, FactSet)"],
//     location: "Houston, TX",
//     salary: "$70,000 - $100,000 per year"
//   },
//   {
//     id: 11,
//     title: "Content Writer",
//     responsibilities: ["Create engaging and informative content for websites, blogs, social media, and other digital platforms", "Research topics and industry trends to generate ideas for new content", "Optimize content for SEO and audience engagement, ensuring it aligns with brand voice and messaging"],
//     qualifications: ["Bachelor's degree in English, Journalism, Communications, or related field", "Excellent writing, editing, and proofreading skills", "Experience with content management systems (e.g., WordPress) and SEO best practices"],
//     location: "Portland, OR",
//     salary: "$45,000 - $70,000 per year"
//   },
// ] 

//   return (
//     <jobDetails.Provider value={jobDescriptions}>  
//     <Career></Career>
//     </jobDetails.Provider>
//   )
// }

// export default data