import React from "react";

function Complete(){
    return(
        <>
        <h1>Successful</h1>
        </>
    )
}

export default Complete;